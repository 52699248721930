<template>
  <SelectFullPage
    id="categoryPane"
    v-model="value"
    :hidden-placeholder="placeholder.length === 0"
    :label="placeholder || t('order.service')"
    :items="categories"
  >
    <template #icon>
      <IconBase v-if="value && hasIcon" :path="`category/${value}.svg`" class="pane-img" />
    </template>
    <template #item-prepend="{ item }">
      <IconBase :path="`category/${item.id}.svg`" class="pane-img" />
    </template>
  </SelectFullPage>
</template>

<script setup>
import { SelectFullPage, IconBase } from '@/UI'

import { useMainEntities } from '@/states/mainEntities'
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  hasIcon: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  }
})
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const mainEntities = useMainEntities()
const categories = mainEntities.category.filter((el) => !el.hidden)
</script>
