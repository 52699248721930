<template>
  <HeaderFullpage :title="props.customPage.headerTitle" has-custom-navigation @back="onRouteBack" />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    gap="40"
  >
    {{ form }}
    <ItemBase :title="t('account.category')">
      <SelectCategory v-model="form.payload.categoryId" />
      <CheckboxTabs
        v-model="form.payload.executor_type"
        :options="businessStatusOptions"
      />
      <InputBase
        v-model="form.payload.name"
        :label="t('common.companyName')"
      />
      <SelectAddress
        v-model="form.payload.address"
        :label="t('settings.address')"
        @update:model-value="onAddress"
      />
    </ItemBase>

    <ItemBase
      v-if="isShowAnimalTypeSelector"
      :title="t('account.animals')"
    >
      <AnimalManySelector v-model="form.payload.animalTypes" />
    </ItemBase>

    <div v-if="isShowAnimalForm" class="gap">
      <ItemBase :title="t('account.kind')">
        <div class="gap">
          <!-- <AnimalTypeSelector v-model="pet.payload.animal_id" /> -->
          <InputSimple
            v-model="pet.payload.name"
            name="Кличка"
            :placeholder="t('account.placeholders.petName')"
          />
          <InputSimple
            v-model="pet.payload.breed"
            name="Порода"
            :placeholder="t('account.placeholders.breed')"
          />
        </div>
      </ItemBase>

      <CheckboxTabs
        v-model="pet.payload.sex"
        :options="sexOptionsComputed"
      />

      <div class="item-inner">
        <ItemBase :title="t('account.bdate')">
          <InputSimple
            v-model="pet.payload.age"
            :name="t('account.placeholders.bdate')"
            data-maska="##.##.####"
            type="decimal"
            :placeholder="t('account.placeholders.bdate')"
          />
        </ItemBase>
        <ItemBase :title="t('common.weight')">
          <InputSimple
            v-model="pet.payload.weight"
            :name="t('common.weight')"
            type="decimal"
            :placeholder="t('common.weight')"
            data-maska="###"
          />
        </ItemBase>
      </div>

      <ItemBase :title="t('account.castration')">
        <CheckboxTabs
          v-model="pet.payload.castration"
          :options="castrationOptions"
        />
      </ItemBase>

      <ItemBase :title="t('account.chip')">
        <CheckboxTabs
          v-model="pet.payload.chip"
          :options="chipOptions"
        />
        <InputSimple
          v-if="pet.payload.chip"
          v-model="pet.payload.chipNumber"
          name="Номер чипа"
          :placeholder="t('account.placeholders.chip')"
        />
      </ItemBase>

      <ItemBase :title="t('account.vac')">
        <div
          v-for="(inputVac, key) in pet.payload.vaccinations"
          :key="key"
          class="vac"
        >
          <InputSimple
            v-model="inputVac.type"
            :placeholder="t('account.placeholders.vacName')"
            class="vac__name"
          />
          <InputDate
            v-model="inputVac.createdAt"
            :placeholder="t('account.placeholders.vacDate')"
          />
          <img
            v-if="pet.payload.vaccinations.length - 1 !== key"
            class="vac__delete"
            src="@/assets/icons/other/add.svg"
            alt=""
            @click="
              pet.payload.vaccinations = pet.payload.vaccinations.filter(
                (el, idx) => idx !== key,
              )
            "
          >
        </div>
      </ItemBase>
      <ItemBase :title="t('account.checkup')">
        <div class="vac">
          <InputSimple
            v-model="pet.payload.lastCheckupDrug"
            :placeholder="t('account.placeholders.checkupName')"
            class="vac__name"
          />
          <InputDate v-model="pet.payload.lastCheckup" />
        </div>
      </ItemBase>

      <ItemBase :title="t('account.chronic')">
        <TextareaBase
          v-model="pet.payload.chronicIlls"
          :rows="6"
          :placeholder="t('account.placeholders.chronic')"
        />
      </ItemBase>
    </div>

    <ItemBase
      v-if="isShowSheduleCalendar"
      :title="t('account.calendar')"
    >
      <InputCheckbox
        v-model="form.payload.daynight"
        :title="t('account.placeholders.daynight')"
      />
      <template v-if="!form.payload.daynight">
        <UniversalPane
          id="dayoff"
          v-model="form.payload.dayoff"
          :placeholder="t('account.placeholders.dayoff')"
          :suggest="t('account.placeholders.dayoffs')"
          :options="daysOptions"
        />
        <UniversalPane
          id="shortday"
          v-model="form.payload.shortDay"
          :placeholder="t('account.placeholders.short')"
          :suggest="t('account.placeholders.shorts')"
          :options="daysOptions"
        />
      </template>
    </ItemBase>

    <template v-if="!form.payload.daynight && isShowSheduleCalendar">
      <ItemBase :title="t('account.time')">
        <div class="two">
          <TimePicker
            id="fdsf"
            v-model="form.payload.timeFrom"
            :placeholder="t('account.placeholders.from')"
          />
          <TimePicker
            id="gfdb"
            v-model="form.payload.timeTill"
            :placeholder="t('account.placeholders.till')"
          />
        </div>
        <InputCheckbox
          v-model="form.payload.break"
          :title="t('account.placeholders.break')"
        />
        <div
          v-if="form.payload.break"
          class="two"
        >
          <TimePicker
            id="fdgsf"
            v-model="form.payload.breakFrom"
            :placeholder="t('account.placeholders.from')"
          />
          <TimePicker
            id="gfdhb"
            v-model="form.payload.breakTill"
            :placeholder="t('account.placeholders.till')"
          />
        </div>
      </ItemBase>

      <ItemBase :title="t('account.shortTime')">
        <div class="two">
          <TimePicker
            id="bff"
            v-model="form.payload.shortDaysTimeFrom"
            :placeholder="t('account.placeholders.from')"
          />
          <TimePicker
            id="brjh"
            v-model="form.payload.shortDaysTimeTill"
            :placeholder="t('account.placeholders.till')"
          />
        </div>
        <InputCheckbox
          v-model="form.payload.breakShortDays"
          :title="t('account.placeholders.break')"
        />
        <div
          v-if="form.payload.breakShortDays"
          class="two"
        >
          <TimePicker
            id="bdfdf"
            v-model="form.payload.shortDaysBreakFrom"
            :placeholder="t('account.placeholders.from')"
          />
          <TimePicker
            id="bbrjhn"
            v-model="form.payload.shortDaysBreakTill"
            :placeholder="t('account.placeholders.till')"
          />
        </div>
      </ItemBase>
    </template>

    <PhotoGallery v-model="form.payload.photos" />
    <ItemBase :title="t('account.contact')">
      <div class="contacts">
        <InputBasePhone
          v-model="form.payload.tel"
          :label="t('account.placeholders.phone')"
        />
        <InputBase
          v-model="form.payload.email"
          :label="t('account.placeholders.mail')"
        />
        <InputBase
          v-model="form.payload.telegram"
          :label="t('account.placeholders.tg')"
        />
        <InputBasePhone
          v-model="form.payload.whatsApp"
          :label="t('account.placeholders.wa')"
        />
        <InputBaseWebsite
          v-model="form.payload.web"
          :label="t('account.placeholders.site')"
        />
      </div>
    </ItemBase>

    <ItemBase :title="t('account.about')">
      <TextareaBase
        v-model="form.payload.about"
        :label="t('account.placeholders.about')"
      />
      <InputBase
        v-if="isShowExperience"
        v-model="form.payload.stag"
        :label="t('account.placeholders.stag')"
      />

      <ServiceSelector
        v-model:catprops="form.payload.accountProps"
        v-model:types="form.payload.account_type_id"
        v-model:fields="form.payload.fields"
        :exec-type="form.payload.executor_type"
        :category="form.payload.categoryId"
      />
    </ItemBase>

    <ItemBase
      v-if="isShowAdditionalServices || isShowMinimalPrice"
      :title="t('account.extra')"
    >
      <TextareaBase
        v-if="isShowAdditionalServices"
        v-model="form.payload.extraservice"
        :label="t('account.placeholders.extra')"
      />
      <InputBase
        v-if="isShowMinimalPrice"
        v-model="form.payload.cost"
        :label="t('account.placeholders.cost')"
      />
    </ItemBase>
    <div class="account__actions">
      <!-- TODO vet-57 вернуть -->
      <ButtonBase
        v-if="props.pageType === 'edit'"
        class="account__actions__item"
        @click=" router.push({
          name: 'account-showcases',
          id: route.params.id,
        }) "
      >
        {{ t('accounts.addShowcase') }}
      </ButtonBase>

      <PhotoGallery
        v-if="isShowLicence"
        v-model="form.payload.licensePhotos"
        :label="t('account.placeholders.licence')"
        activator-class-name="account__actions__item"
      />

      <ButtonBase
        class="account__actions__item"
        @click="submit"
      >
        {{ props.customPage.buttonText }}
      </ButtonBase>
    </div>
  </LayoutBase>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import TimePicker from '@/components/TimePicker'
import {
  HeaderFullpage,
  CheckboxTabs,
  ItemBase,
  InputCheckbox,
  InputSimple,
  TextareaBase,
  ButtonBase,
  InputBase,
  InputBasePhone,
  InputBaseWebsite,
  SelectCategory,
  SelectAddress,
} from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { AnimalManySelector } from '@/components/AnimalManySelector'
import { daysOptions } from './constants'
import { UniversalPane } from '@/components/UniversalPane'
import { PhotoGallery } from '@/components/PhotoGallery'
import { ServiceSelector } from '@/components/ServiceSelector'
import { successToast, errorToast } from '@/helpers/showToast'
import { editAccount, getAccount, createAccount } from './api'
import { PageLoader } from '@/components/PageLoader'
import { useRouter, useRoute } from 'vue-router'
import { uploadPhoto } from '@/states/api'
import { isValide } from '@/helpers/validate'
import { InputDate } from '@/components/InputDate'
import { useNavigation } from "@/composables/useNavigation";
import { useMainEntities } from '@/states/mainEntities'
import { useConfirm } from '@/composables/useConfirm'
const mainEntities = useMainEntities()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const isLoading = ref(true)

const props = defineProps({
  customPage: {
    type: Object,
    default: () => {},
  },
  pageType: {
    type: String,
    required: true,
  },
})

const form = reactive({
  payload: {
    account_type_id: [],
    about: '',
    accountProps: [],
    address: '',
    break: false,
    breakFrom: '',
    breakTill: '',
    breakShortDays: false,
    shortDaysBreakFrom: '',
    shortDaysBreakTill: '',
    categoryId: 1,
    coords: [],
    cost: '',
    daynight: false,
    dayoff: [],
    email: '',
    extraservice: '',
    fields: {},
    isClinic: false,
    licensePhotos: [],
    name: '',
    photos: [],
    shortDay: [],
    stag: '',
    tel: '',
    telegram: '',
    web: '',
    whatsApp: '',
    animalTypes: [],
    executor_type: 1,
    timeFrom: '',
    timeTill: '',
    shortDaysTimeFrom: '',
    shortDaysTimeTill: '',
  },
})

const validateParams = () => [
  {
    value: form.payload.name,
    label: 'Название',
  },
  {
    value: form.payload.address,
    label: 'Адрес',
  },
  {
    value:
      form.payload.animalTypes.length || !isShowAnimalTypeSelector.value
        ? true
        : '',
    label: 'Животные с которыми работаете',
  },
  {
    value: form.payload.tel,
    rule: 'isPhone',
    label: 'Телефон',
  },
  {
    value: form.payload.email,
    label: 'Почта',
  },
  {
    value: form.payload.about,
    label: 'О себе',
  },
]

const onAddress = ({ address, coords }) => {
  form.payload.address = address
  form.payload.coords = coords
}

const mergeObjects = (obj1, obj2) => {
  for (const key in obj1) {
    // Проверяем, есть ли такое свойство у второго объекта
    if (Object.hasOwn(obj2, key)) {
      // Если есть, добавляем свойство из первого объекта в новый объект
      obj1[key] = obj2[key]
    }
  }
}

const init = async () => {
  if (props.pageType === 'edit') {
    const { id } = route.params
    const { accounts } = await getAccount({ id })
    Object.assign(form.payload, accounts[0])
    Object.assign(pet, accounts[0].pet)
  }

  const { fork } = route.query
  if (fork && props.pageType === 'create') {
    const { accounts } = await getAccount({ id: fork })
    mergeObjects(form.payload, accounts[0])
    Object.assign(pet, accounts[0].pet)
  }
  isLoading.value = false
}

onMounted(() => {
  init()
})

// TODO: логически разявязать условия
const businessStatusOptions = computed(() => {
  const choosedCategory = mainEntities.category.find(
    (el) => el.id === form.payload.categoryId,
  )
  const { name_1, name_2 } = choosedCategory
  return [
    { value: 1, label: name_2 || t('accountType.selfEmployed') },
    { value: 2, label: name_1 || t('accountType.business') },
  ]
})

const isShowAnimalTypeSelector = computed(() => (
  ![5, 24].includes(form.payload.categoryId) &&
    !(form.payload.categoryId === 13) &&
    !(form.payload.categoryId === 8 && form.payload.executor_type === 1)
))

const isShowExperience = computed(() => {
  if (form.payload.categoryId === 8 && form.payload.executor_type === 1) {
    return false
  }
  return form.payload.categoryId !== 13
})

const isShowAdditionalServices = computed(() => {
  if (form.payload.categoryId === 8 && form.payload.executor_type === 1) {
    return false
  }
  return form.payload.categoryId !== 13
})

const isShowSheduleCalendar = computed(() => {
  if (form.payload.categoryId === 8 && form.payload.executor_type === 1) {
    return false
  }
  if (form.payload.categoryId === 13) {
    return false
  }
  return true
})

const isShowMinimalPrice = computed(() => {
  if (form.payload.categoryId === 8 && form.payload.executor_type === 1) {
    return false
  }
  return form.payload.categoryId !== 13
})

const isShowLicence = computed(() => form.payload.categoryId !== 13)

const isShowAnimalForm = computed(() => {
  //TODO: logic
  if (form.payload.categoryId === 8 && form.payload.executor_type === 2) {
    return false
  }
  return [8, 13].includes(form.payload.categoryId)
})

// const isShowAnimalSelector = computed(() => {
//   //TODO: logic
//   if (form.payload.categoryId === 13) {
//     return form.payload.executor_type === 1
//   } else if (
//     (form.payload.categoryId === 8 && form.payload.executor_type === 1) ||
//     form.payload.categoryId === 13
//   ) {
//     return true
//   } else {
//     return false
//   }
// })
////////////////////

//Животное в вязке
const pet = reactive({
  payload: {
    age: '',
    breed: '',
    castration: false,
    chip: false,
    chipNumber: '',
    chronicIlls: '',
    kind: '',
    lastCheckup: '',
    lastCheckupDrug: '',
    pet_name: '',
    photos: [],
    sex: 'male',
    vaccinations: [
      {
        createdAt: '',
        type: '',
      },
    ],
    weight: '',
  },
})

const sexOptions = [
  {
    value: 'male',
    label: t('common.male'),
  },
  {
    value: 'female',
    label: t('common.female'),
  },
  {
    value: 'sexless',
    label: t('common.sexless'),
  },
]

const castrationOptions = [
  {
    value: true,
    label: t('common.yes'),
  },
  {
    value: false,
    label: t('common.no'),
  },
]

const sexOptionsComputed = computed(() => {
  //Только у "Насекомых" и "Рептилий и экзотики" есть бесполость
  const sexlessAnimals = [7, 11]

  if (sexlessAnimals.includes(pet.payload.animal_id)) {
    return sexOptions
  }
  return sexOptions.filter((el) => el.value !== 'sexless')
})
const chipOptions = [
  {
    value: true,
    label: t('common.has'),
  },
  {
    value: false,
    label: t('common.notHas'),
  },
]

watch(pet.payload, () => {
  const { createdAt, type } = pet.payload.vaccinations.at(-1)

  if (createdAt && type) {
    pet.payload.vaccinations.push({
      createdAt: '',
      type: '',
    })
  }
})

watch(() => pet.payload.chip, (val) => {
  if (val === false) {
    pet.payload.chipNumber = ''
  }
})
//Животное в вязке

const { onConfirm } = useConfirm();

const addShowcase = async (id) => {
  const hasCloseButton = true;

  const response = await onConfirm({
    title: t('account.addShowcase.title'),
    yesButton: { danger: false, title: t('account.addShowcase.addProduct') },
    noButton: { danger: false, title: t('account.addShowcase.addService') },
    anotherButton: { title: t('account.addShowcase.addPrice') },
    hasCloseButton,
  })

  if (response === 'another') {
    router.push(`/account-showcases/${id}/prices/create`)
    return;
  }

  if (hasCloseButton) {
    return;
  }

  if (response) {
    router.push(`/account-showcases/${id}/products/create`)
  } else {
    router.push(`/account-showcases/${id}/services/create`)
  }
}

const submit = async () => {
  if (!isValide(validateParams(), t)) {
    return
  }
  isLoading.value = true

  const { paths } = await uploadPhoto({ photos: form.payload.photos })
  form.payload.photos = paths

  const { paths: lic } = await uploadPhoto({
    photos: form.payload.licensePhotos,
  })
  form.payload.licensePhotos = lic

  form.account_type_id = form.payload.account_type_id

  if (isShowAnimalForm.value) {
    form.payload.pet = pet
  }

  if (props.pageType === 'edit') {
    const { id } = route.params
    form.id = id
  }

  if (props.pageType === 'edit') {
    const { success } = await editAccount({ form })

    if (success) {
      successToast({ description: t('account.toast.editSuccess') })
      router.back()
    } else {
      errorToast({ description: t('toast.somethingWrong') })
    }
  }
  if (props.pageType === 'create') {
    const { id, success } = await createAccount({ form })

    if (success) {
      successToast({ description: t('account.toast.createModeration') })
      router.back()
      addShowcase(id);
    } else {
      errorToast({ description: t('toast.somethingWrong') })
    }
  }

  isLoading.value = false
}

const { goBack } = useNavigation();
const onRouteBack = async (event) => {
  event.preventDefault();
  const title = props.pageType === 'create' ? t('account.toast.stopCreation') : t('account.toast.stopEdit');
  const response = await onConfirm({ title })
  if (response) {
    goBack();
  }
}
</script>

<style lang="scss">
.two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.contacts {
  display: grid;
  gap: 10px;
}

.gap {
  display: grid;
  gap: 40px;
}

////////////////
/// animal
///
.mb {
  margin-bottom: 10px;
}

.item-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.vac {
  display: grid;
  grid-template-columns: 1fr 120px 24px;
  align-items: center;
  gap: 10px;

  &__delete {
    transform: rotate(45deg);
  }
}

.account__actions {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 75px;
  &__item {
    width: 100% !important;
    height: 60px !important;
  }
}
</style>
