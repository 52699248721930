<template>
  <div class="error-text">
    <slot>
      {{ localizedText }}
    </slot>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  variables: {
    type: Object,
    default: () => {},
  }
})
const { t } = useI18n()
const localizedText = computed(() => t(`error.${props.text}`, props.variables))
</script>

<style scoped lang="scss">
.error-text {
  margin-top: 8px;
  color: #ff5858;
  font-size: 12px;
}
</style>
