<template>
  <div class="input__wrapper">
    <input
      v-bind="$attrs"
      ref="inputRef"
      class="input-base"
      :class="{
        'has-value': String(modelValue).length > 0
      }"
      :value="modelValue"
      @input="onInput"
      @focus="onFocus"
      @keypress.enter="onEnter"
    >
    <span class="input__label">
      {{ label }}
    </span>
    <transition name="input__error-text">
      <p
        v-if="errors.length"
        class="input__error-text"
      >
        {{ errors[0].$message }}
      </p>
    </transition>

    <ErrorText v-if="isErrorVisible" :text="error[0].message" :variables="error[0].variables" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'

import { ErrorText } from '@/UI';

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  // this field needs to support old logic
  errors: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Array,
    default: () => [],
  },
  hasAutofocus: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue', 'enter', 'focus'])
const inputRef = ref(null)

const onInput = ({ target }) => {
  emit('update:modelValue', target.value)
  isErrorVisible.value = false;
}
const onFocus = () => {
  emit('focus');
}
const onEnter = () => {
  emit('enter');
}

onMounted(() => {
  if (props.hasAutofocus && inputRef.value) {
    inputRef.value.focus();
  }
})

const isErrorVisible = ref(false);
watch(computed(() => props.error && props.error[0]), (value) => {
  if (value) {
    isErrorVisible.value = true;
  }
})

</script>

<style scoped lang="scss">
[theme='dark'] {
  .input-base {
    background: #37363c;
    border: 1px solid #37363c;
    color: #fff;
  }
}

.input__wrapper {
  position: relative;
  color: #6b7090;
  font-size: 15px;
  font-weight: 600;
}

.input-base {
  padding: 26px 20px 10px;
  height: 60px;
  border: 1px solid #e8eafc;
  background: #fff;
  font-size: 16px;
  border-radius: 20px;
  transition: border 0.3s;

  &:focus {
    border: 1px solid #5b43ef;
  }

}

.input__label {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 16px;
  pointer-events: none;
  transition: all 0.2s;
}

.input-base:focus + .input__label,
.input-base.has-value + .input__label{
  top: 10px;
  font-size: 14px;
}

.input__error-text {
  position: absolute;
  left: 8px;
  top: calc(100% + 5px);
  color: #ff5858;
  font-size: 12px;
  transition: all 0.3s;
  margin: 0;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
