<template>
  <RouterView />
  <template v-if="isLoaded">
    <CheckVersion />
    <AppConfirm />
    <AppDemoMode />
  </template>
</template>

<script setup>
import { registerNotifications } from '@/helpers/push';
import { CheckVersion } from './PreviewScreens/CheckVersion'
import { safeAreaPromise } from '@/helpers/getSafeArea';
import { useRouter } from 'vue-router'
import { onMounted } from 'vue';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { useDevice } from '@/composables/useDevice';
import { useSystem } from '@/composables/useSystem';

import AppConfirm from './components/AppConfirm.vue';
import AppDemoMode from './components/AppDemoMode.vue';

import { checkAppVersion } from './api/checkAppVersion';
import { onBeforeMount } from 'vue';
import { ref } from 'vue';
import { useUserStore } from '@/states/userStore';

const { setDeviceType } = useDevice();

const isLoaded = ref(false);
const { getUser } = useUserStore();
onBeforeMount(async () => {
  await setDeviceType();
  isLoaded.value = true;

  getUser();
})

const router = useRouter()

function findByKey(obj, key) {
  // Создаем стек, чтобы отслеживать объекты для обхода
  const stack = [obj];

  while (stack.length > 0) {
    const currentObj = stack.pop();

    for (const prop in currentObj) {
      if (Object.hasOwn(currentObj, prop)) {
        const value = currentObj[prop];

        if (prop === key) {
          return value; // Нашли ключ
        }

        if (typeof value === 'object') {
          stack.push(value); // Добавляем объекты в стек для дальнейшего поиска
        }
      }
    }
  }

  return null; // Ключ не найден
}

const goToRouteFromNotification = (notification) => {
  const route = findByKey(notification, 'route')
  const routeId = findByKey(notification, 'routeId')

  if (!route || !routeId) {return}

  switch (route) {
  case "chat":
    router.push({ path: `/chat/${routeId}` });
    break;
  case "offer":
    router.push({
      path: `/offer/${routeId}`,
    });
    break;
  case "delegate":
    router.push({
      path: `/account/${routeId}`,
    });
    break;

  default:
    console.log("to default route");
    break;
  }
};

const initPushMsgs = async () => {
  if ((await Device.getInfo()).platform === 'web') {return}
  PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    goToRouteFromNotification(notification)
  });
}
initPushMsgs()
registerNotifications()

const init = async () => {
  if ((await Device.getInfo()).platform === 'web') {return}
  if (window?.screen?.orientation) {
    window?.screen?.orientation?.lock('portrait');
  }
}

const initSafeArea = async () => {
  const safeArea = await safeAreaPromise()
  document.documentElement.style.setProperty('--statusbar', `${safeArea.top }px`);
}

const { getDeviceTheme, listenConnection } = useSystem()
getDeviceTheme();
listenConnection();

onMounted(() => {
  initSafeArea()
  init()
  checkAppVersion();
})
</script>

<!-- <style lang="scss" src="./styles/main.scss" /> -->
