export const useValidation = ({ schema, fields }) => {
  const validate = async () => {
    const readonlyFields = fields.value ? fields.value : fields;
    try {
      const result = await schema.validate(readonlyFields, { abortEarly: false });
      return { result, isSuccess: true, }
    } catch (error) {
      const frontendErrors = {};
      error.inner.forEach((error) => {
        frontendErrors[error.path] = frontendErrors[error.path] ? frontendErrors[error.path] : [];

        const variables = error.params[error.type] ? {
          [error.type]: error.params[error.type],
        } : {};

        frontendErrors[error.path].push({
          message: error.errors[0],
          variables,
        });
      });

      console.log(frontendErrors);
      return { errors: frontendErrors, isSuccess: false, }
    }
  }
  return { validate }
}
