import { createApp } from 'vue'
import { vMaska } from "maska"

import App from './app/App.vue';
import FlagIcon from 'vue-flag-icon';
import router from './router'

import 'animate.css';
import '@/app/styles/main.scss'
import { useDevice } from '@/composables/useDevice';
import { i18n, initLocalization } from '@/plugins/localization';
import { vuetify } from '@/plugins/vuetify';
import { pinia } from '@/plugins/pinia'
import { initSentry } from '@/plugins/sentry';

export const app = createApp(App)

export { i18n }

initSentry(app);

app
  .use(i18n)
  .directive("maska", vMaska)
  .use(router)
  .use(pinia)
  .use(FlagIcon)
  .use(vuetify)
  .mount('#app');

const { setDeviceType } = useDevice();
setDeviceType();
initLocalization();
