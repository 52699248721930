<template>
  <div class="profile-wrapper">
    <router-link class="close-button" to="/">
      <img src="@/assets/icons/other/close.svg">
    </router-link>
    <div class="header">
      <ProfilePicture :user="user" />
      <div class="name">
        {{ user.name || '&nbsp;' }}
      </div>
      <div class="phone">
        {{ userPhone || '&nbsp;' }}
      </div>
    </div>

    <div class="menu-list">
      <ListItem
        :icon="UserIcon"
        :title="t('menu.profile')"
        to="/profile"
      />
      <ListItem
        :icon="CompanyIcon"
        :title="t('menu.myCompanies')"
        to="/companies"
      />
      <ListItem
        :icon="PetIcon"
        :title="t('menu.myPets')"
        to="/animals"
      />
      <ListItem
        :icon="SettingsIcon"
        :title="t('menu.preferencies')"
        to="/preferencies"
      />
      <ListItem
        :icon="QuestionIcon"
        :title="t('menu.support')"
        to="/support"
      />
    </div>
  </div>
</template>

<script setup>
import { useUserStore, useUserData } from '@/states/userStore';

import ProfilePicture from '@/components/ProfilePicture.vue';

import UserIcon from '@/assets/icons/other/user-gray.svg'
import CompanyIcon from '@/assets/icons/other/company-gray.svg'
import PetIcon from '@/assets/icons/other/pet-gray.svg'
import SettingsIcon from '@/assets/icons/other/settings-gray.svg'
import QuestionIcon from '@/assets/icons/other/question-gray.svg'

import ListItem from './ListItem.vue';

const { getUser } = useUserStore();
const { user, userPhone } = useUserData();

getUser()

</script>

<style lang="scss" scoped>
[theme="dark"] {
  .name, .phone {
    color: #ffffff;
  }

  .close-button {
    background-color: #1b1b1b;
  }
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  padding: 50px 20px 20px;

  @include flex-center();
  flex-direction: column;
}

.name {
  font-size: 24px;
  @include font-semibold;

  margin-bottom: 6px;
}
.phone {
  font-size: 14px;
  color: #8E92AF;
  @include font-bold;
}

.close-button {
  @include flex-center;
  background: white;
  border-radius: 16px;
  width: 50px;
  height: 50px;

  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
