<template>
  <SelectFullPage
    id="selectAddress"
    ref="selectAddress"
    v-model="value"
    class="select-address"
    v-bind="$attrs"
    :items="suggestions"
    :is-loading="isLoading"
    return-object
    @open="onOpen"
  >
    <template #prepend>
      <InputBase
        id="inputAddress"
        ref="inputAddress"
        v-model="search"
        has-autofocus
        :label="t('component.address.enterAddress')"
        class="input-address"
      />

      <ButtonBase style="min-height: 55px;" @click="detectAddress">
        {{ isLoadingDetect ? t('common.loading') : t('component.address.detect') }}
      </ButtonBase>
    </template>
    <template #data>
      <div
        v-for="item in suggestions"
        class="address-item"
        @click.stop="onSelectAddress(item)"
      >
        {{ item.address }}
      </div>
    </template>
    <template #item-prepend="{item}">
      {{ item.address }}
    </template>
    <template #modelValue>
      {{ value }}
    </template>
  </SelectFullPage>
  <ErrorText v-if="isErrorVisible" :text="error[0].message" />
</template>

<script setup>
import { SelectFullPage, InputBase, ButtonBase, ErrorText } from '@/UI'

import { computed, ref } from 'vue';

import { searchAddress, getAddress } from '@/app/api/address'
import { getCurrentPosition } from '@/helpers/getCurrentPosition'
import { errorToast } from '@/helpers/showToast'
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  error: {
    type: Array,
    default: () => [],
  }
})
const emit = defineEmits(['update:modelValue'])

const isErrorVisible = ref(false);

const value = computed({
  get() {
    return props.modelValue
  },
  set(payload) {
    emit('update:modelValue', payload)
  },
})

const selectAddress = ref(null);
const onSelectAddress = (payload) =>{
  setTimeout(() => {
    isErrorVisible.value = false;
    search.value = payload.address;
    selectAddress.value.onChoose(payload);
  }, 50);
}

const search = ref('');
const suggestions = ref([]);

const isLoading = ref(false);
watch(search, async (val) => {
  if (!val) {
    return suggestions.value = []
  }
  isLoading.value = true;
  suggestions.value = await searchAddress({ search: val })
  isLoading.value = false;
}, { immediate: true })

const isLoadingDetect = ref(false);
const detectAddress = async () => {
  isLoadingDetect.value = true
  try {
    const { coords } = await getCurrentPosition()
    const address = await getAddress({ coords })

    onSelectAddress({ coords, address });
    close()
  } catch (e) {
    console.error(e);
    errorToast({ description:  t('component.address.detectImpossible') })
  } finally {
    isLoadingDetect.value = false
  }
}

const onOpen = () => {
  setTimeout(() => {
    const inputAddress = document.querySelector('#inputAddress')
    inputAddress.focus();
  }, 100);
}

watch(computed(() => props.error && props.error[0]), (value) => {
  if (value) {
    isErrorVisible.value = true;
  }
})

</script>

<style lang="scss">
.select-address.has-value{
  padding-top: 34px !important;
  max-height: max-content !important;
  height: auto !important;
}

</style>

<style lang="scss" scoped>
[theme="dark"] {
  .address-item {
    border: 1px solid #5B5F7C;
    background: #5B5F7C;
    color: #fff;
  }
}

.address-item {
  padding: 20px;
  border: 1px solid #E8EAFC;
  background: #FFF;
  border-radius: 20px;
  color: #5B5F7C;
}
</style>
