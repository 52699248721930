<template>
  <div
    :class="[
      'tabs',
      {
        'tabs_readonly': computedReadonly
      }
    ]"
  >
    <div
      v-for="item in props.options"
      :key="item.value"
      class="tabs__item"
      :class="{
        tabs__item_active: item.value === props.modelValue
      }"
      @click="chooseActiveTab(item.value)"
    >
      {{ item.label }}
    </div>
    <div
      class="tabs__active"
      :style="{
        width: `${100 / props.options.length}%`,
        transform: `translateX(${100 * currentIndex}%)`
      }"
    />
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue'
const emit = defineEmits(['update:modelValue', 'notClickable'])

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [Boolean, String, Number],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
})

onBeforeMount(() => {
  const activeTab = Number(sessionStorage.getItem('activeTab'));
  if (activeTab >= 0) {
    emit('update:modelValue', activeTab)
  }
})

const chooseActiveTab = (value) => {
  if (props.disabled) {return emit('notClickable')}
  sessionStorage.setItem('activeTab', value);
  emit('update:modelValue', value)
}

const computedReadonly = computed(() => props.readonly || props.options.length === 1)

const currentIndex = computed(() => {
  const currentEl = props.options.find((el) => el.value === props.modelValue)
  return props.options.indexOf(currentEl) || 0
})
</script>

<style lang="scss" scoped>
[theme='dark'] {
  .tabs {
    background: #37363c;
    border: 1px solid #37363c;
  }
}

.tabs {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  border-radius: 50px;
  border: 1px solid #e8eafc;
  background: #fff;
  height: 40px;
  align-items: center;
  cursor: pointer;
  position: relative;
  &_readonly {
    .tabs__active {
      box-shadow: none;
      background: #ccc;
    }
    .tabs__item {
      color: #202020;
    }
  }
  &__item {
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    text-overflow: ellipsis;
    position: relative;
    z-index: 5;
    color: var(--purpleColor);
    height: 100%;
    font-size: 14px;
    transition: all 0.3s ease;

    @include font-bold;

    &_active {
      color: var(--baseColor);
    }
  }
  &__active {
    position: absolute;
    background: var(--bgPrimary);
    box-shadow: var(--primary-shadow);
    height: 100%;
    top: 0;
    width: 50%;
    border-radius: inherit;
    transition: all 0.3s ease;
  }
}

</style>
