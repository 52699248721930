import { ref } from "vue"
import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { useValidation } from '@/composables/useValidation';

const PHONE_RULE = yup.string()
  .test('is-valid-phone', 'isPhone', value => {
    if (!value) {return true;}
    return isValidPhoneNumber(value);
  })
  .nullable()
  .transform(value => (value === '' ? null : value));

const URL_RULE = yup
  .string()
  .matches(/^(https?:\/\/)([^\s]+\.[^\s]+)/, 'isUrl')
  .max(200, 'notMaxThan')
  .nullable()
  .transform(value => (value === '' ? null : value));

export const useCompanyValidation = ({ companyInfo }) => {
  const commonErrors = ref({
    mainInfoStep: undefined,
    addressStep: undefined,
  })

  const processValidation = async ({ schema, errorField }) => {
    const { validate } = useValidation({ schema, fields: companyInfo })
    const { isSuccess, errors } = await validate();
    commonErrors.value[errorField] = errors;
    return isSuccess;
  }

  const validateFirstStep = async () => {
    const schema = yup.object({
      name: yup.string().required('isRequired'),
      logo: yup.string().required('isRequired'),
    })
    return processValidation({ schema, errorField: 'mainInfoStep' })
  }

  const validateAddressStep = async () => {
    const schema = yup.object({
      address: yup.string().required('isRequired'),
    })
    return processValidation({ schema, errorField: 'addressStep' })
  }

  const validateAnimalStep = async () => {
    const schema = yup.object({
      animalTypes: yup.array().required('isRequired').test({
        message: 'isRequired',
        test: arr => arr.length > 0,
      }),
    })
    return processValidation({ schema, errorField: 'animalStep' })
  }

  const validateContactStep = async () => {
    const schema = yup.object({
      email: yup.string()
        .email('isEmail'),
      telegram: yup.string()
        .matches(/^@.+$/, 'atIsRequired')
        .min(5, 'minLength')
        .max(50, 'notMaxThan')
        .nullable()
        .transform(value => (value === '' ? null : value)),
      tel: PHONE_RULE,
      whatsApp: PHONE_RULE,
      web: URL_RULE,
    })
    return processValidation({ schema, errorField: 'contactsStep' })
  }

  return {
    commonErrors,
    validateFirstStep,
    validateAddressStep,
    validateAnimalStep,
    validateContactStep,
  }
}
