<template>
  <BlockHeader
    :title="t('companyCreate.mainInfoStep.title')"
    :text="t('companyCreate.mainInfoStep.description')"
  />
  <InputBase
    v-model="companyInfo.name"
    :label="t('common.companyName')"
    :error="errors?.name"
  />
  <div style="margin-bottom: 20px;" />
  <!-- TODO create file uploader component -->
  <div class="flex-center" style="flex-direction: column;">
    <ButtonBase variant="light" small width-auto @click="onContextOpen">
      {{ t('companyCreate.mainInfoStep.uploadLogo') }}
    </ButtonBase>
    <ErrorText v-if="isFileUploaderErrorVisible && errors?.logo && errors?.logo[0]" :text="errors?.logo[0].message" />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useFileDialog } from '@vueuse/core';

import BlockHeader from '../components/BlockHeader.vue';

import { InputBase, ButtonBase, ErrorText } from '@/UI';

const companyInfo = defineModel({ type: Object, default: () => {} })

const { open: onContextOpen, onChange, files } = useFileDialog({
  multiple: false,
  reset: false,
  accept: import.meta.env.VITE_ACCEPT_PHOTO
})

const isFileUploaderErrorVisible = ref(false);
onChange(() => {
  companyInfo.value.logo = files.value
  isFileUploaderErrorVisible.value = false;
})

const props = defineProps({
  errors: {
    type: Object,
    default: () => {},
  }
})

watch(computed(() => props.errors?.logo && props.errors?.logo[0]), (value) => {
  if (value) {
    isFileUploaderErrorVisible.value = true;
  }
})

</script>

<style scoped lang="scss">
</style>
