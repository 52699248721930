
import { createI18n } from 'vue-i18n'
import { languages } from './locales'

import { app } from "@/main"
import { useStore } from '@/states/index';

export const i18n = createI18n({
  legacy: false,

  fallbackLocale: 'ru',
  messages: languages,
  locale: 'ru'
})

export const initLocalization = () => {
  const store = useStore()
  i18n.global.locale.value = store.language;
  app.config.globalProperties.t = i18n.global.t;
}

export const updateLanguage = (language) => {
  const store = useStore()
  store.$patch({ language })
  i18n.global.locale.value = language;
}

export const changeLanguage = (language, oldLanguage) => {
  updateLanguage(language);
  setTimeout(() => {
    if (language !== oldLanguage) {
      window.location.href = '/';
    }
  }, 0);
}

export const getLanguage = () => {
  const store = useStore()
  return store.language;
}
