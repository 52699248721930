<template>
  <HeaderFullpage :title="t('companyCreate.title')" has-custom-navigation @back="onBack" />

  <div class="registration-wrapper">
    <MainInfo v-if="currentStep === 1" v-model="companyInfo" :errors="commonErrors.mainInfoStep" />
    <CompanyType v-if="currentStep === 2" v-model="companyInfo" :errors="commonErrors.typeStep" />
    <AddressStep v-if="currentStep === 3" v-model="companyInfo" :errors="commonErrors.addressStep" />
    <AnimalStep v-if="currentStep === 4" v-model="companyInfo" :errors="commonErrors.animalStep" />
    <ContactInfo v-if="currentStep === 5" v-model="companyInfo" :errors="commonErrors.contactsStep" />
    <WorkingHours v-if="currentStep === 6 && isShowShedule" v-model="companyInfo" :errors="commonErrors.hoursStep" />
    <AdditionalInfo v-if="currentStep === (isShowShedule ? 7 : 6)" v-model="companyInfo" :errors="commonErrors.infoStep" />

    <div style="flex: 1" />

    <Actions
      :index="currentStep"
      :max-index="steps"
      :is-last-step="currentStep === steps"
      @back="onBack"
      @next="onNext"
      @create="onCompanyCreate"
    />
  </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'

import { HeaderFullpage } from '@/UI';

import MainInfo from './steps/MainInfo.vue';
import CompanyType from './steps/CompanyType.vue';
import AddressStep from './steps/AddressStep.vue';
import AnimalStep from './steps/AnimalStep.vue';
import ContactInfo from './steps/ContactInfo.vue';
import WorkingHours from './steps/WorkingHours.vue';
import AdditionalInfo from './steps/AdditionalInfo.vue';

import Actions from './components/Actions.vue';
import { useCompanyValidation } from './useCompanyValidation';

import { successToast, errorToast } from '@/helpers/showToast'
import { uploadPhoto } from '@/states/api'
import { createCompany } from './api';

import { useConfirm } from '@/composables/useConfirm'

const { t } = useI18n()

const currentStep = ref(1);
const steps = computed(() => isShowShedule.value ? 7 : 6);

const companyInfo = reactive({
  name: '',
  logo: null,

  executor_type: 1,
  categoryId: 1,

  address: '',
  coords: [],

  animalTypes: [],

  email: '',
  tel: '',
  telegram: '',
  whatsApp: '',
  web: '',

  dayoff: [],
  shortDay: [],
  timeFrom: '',
  timeTill: '',
  shortDaysTimeFrom: '',
  shortDaysTimeTill: '',
  daynight: false,
  break: false,
  breakFrom: '',
  breakTill: '',
  breakShortDays: false,
  shortDaysBreakFrom: '',
  shortDaysBreakTill: '',

  about: '',
  extraservice: '',
  stag: '',
  licensePhotos: [],
  accountProps: [],
  account_type_id: [],
  fields: {},

  cost: '',

  isClinic: false,
  photos: [],
})

const {
  commonErrors,
  validateFirstStep,
  validateAddressStep,
  validateAnimalStep,
  validateContactStep,
} = useCompanyValidation({ companyInfo })

const router = useRouter();
const onBack = () => {
  if (currentStep.value === 1) {
    router.push('/companies');
    return;
  }

  if (currentStep.value > 1) {
    currentStep.value -= 1;
  }
}

const processNextStep = (isSuccess = true) => {
  if (isSuccess) {
    currentStep.value += 1
  }
}
const onNext = async () => {
  if (currentStep.value === 1) {
    validateFirstStep().then(processNextStep)
  } else if (currentStep.value === 2) {
    processNextStep()
  } else if (currentStep.value === 3) {
    validateAddressStep().then(processNextStep)
  } else if (currentStep.value === 4) {
    validateAnimalStep().then(processNextStep)
  } else if (currentStep.value === 5) {
    validateContactStep().then(processNextStep)
  } else {
    processNextStep()
  }
}

const isShowShedule = computed(() => {
  if (companyInfo.categoryId === 8 && companyInfo.executor_type === 1) {
    return false
  }
  if (companyInfo.categoryId === 13) {
    return false
  }
  return true
})

const addShowcase = async (id) => {
  const hasCloseButton = true;

  const { onConfirm } = useConfirm();
  const response = await onConfirm({
    title: t('account.addShowcase.title'),
    yesButton: { danger: false, title: t('account.addShowcase.addProduct') },
    noButton: { danger: false, title: t('account.addShowcase.addService') },
    anotherButton: { title: t('account.addShowcase.addPrice') },
    hasCloseButton,
  })

  if (response === 'another') {
    router.push(`/account-showcases/${id}/prices/create`)
    return;
  }

  if (hasCloseButton) {
    return;
  }

  if (response) {
    router.push(`/account-showcases/${id}/products/create`)
  } else {
    router.push(`/account-showcases/${id}/services/create`)
  }
}

const onUploadPhoto = async(field) => {
  const { paths } = await uploadPhoto({ photos: companyInfo[field] })
  if (paths) {
    companyInfo[field] = paths
  }
}

const isLoading = ref(false);
const onCompanyCreate = async () => {
  isLoading.value = true

  const { paths } = await uploadPhoto({ photos: Array.from(companyInfo.logo) })
  companyInfo.logo = paths[0].path

  await onUploadPhoto('photos')
  await onUploadPhoto('licensePhotos')

  const { id, success } = await createCompany(companyInfo)

  if (success) {
    successToast({ description: t('account.toast.createModeration') })
    router.back()
    addShowcase(id);
  } else {
    errorToast({ description: t('toast.somethingWrong') })
  }
}

</script>

<style scoped lang="scss">
.registration-wrapper {
  padding: 40px 20px 20px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
