<template>
  <BlockHeader
    :title="t('companyCreate.addressStep.title')"
    :text="t('companyCreate.addressStep.description')"
  />

  <SelectAddress
    v-model="companyInfo.address"
    :label="t('settings.address')"
    :error="errors?.address"
    @update:model-value="onSelectAddress"
  />
</template>

<script setup>
import BlockHeader from '../components/BlockHeader.vue';
import { SelectAddress } from '@/UI'

const companyInfo = defineModel({ type: Object, default: () => {} })
const onSelectAddress = ({ address, coords }) => {
  companyInfo.value.address = address
  companyInfo.value.coords = coords
}

defineProps({
  errors: {
    type: Object,
    default: () => {},
  }
})
</script>

<style scoped lang="scss">
</style>
