<template>
  <BlockHeader
    :title="t('companyCreate.typeStep.title')"
    :text="t('companyCreate.typeStep.description')"
  />
  <VariantToggle
    v-model="companyInfo.executor_type"
    :options="companyTypes"
    style="margin-bottom: 20px;"
  />
  <SelectCategory
    v-model="companyInfo.categoryId"
    :has-icon="false"
    :placeholder="t('companyCreate.typeStep.companyType')"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n';

import BlockHeader from '../components/BlockHeader.vue';

import { VariantToggle, SelectCategory } from '@/UI';

const { t } = useI18n();

const companyInfo = defineModel({ type: Object, default: () => {} })
const companyTypes = [
  { value: 1, text: t('accountType.selfEmployed') },
  { value: 2, text: t('accountType.business') }
]
</script>

<style scoped lang="scss">
</style>
