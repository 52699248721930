import { api } from "@/app/api"
import { useUserData } from '@/states/userStore';

export const getCompaniesList = async () => {
  try {
    const { user } = useUserData();
    const { data } = await api.get('/v2/accounts')

    const result = data.accounts.map(company => {
      const isCurrentUserAnOwner = company.owner_id === user.value.id;

      return { ...company, isCurrentUserAnOwner }
    })
    return result
  } catch (error) {

    console.error(error)
  }
}

export const deleteCompany = async ({ id }) => {
  try {
    const { data } = await api.delete('/v1/accounts', {
      data: { id }
    })
    return data
  } catch (error) {

    console.error(error)
  }
}
