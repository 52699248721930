<template>
  <div class="photo__wrapper">
    <SpinerBase v-if="isLoading" />
    <img
      v-else
      :src="props.path || nophoto"
      alt=""
      class="photo"
    >
  </div>
</template>

<script setup>
import { useImage } from '@vueuse/core'
import { SpinerBase } from '@/UI'
import nophoto from './assets/nophoto.svg'

const props = defineProps({
  path: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: '150px'
  }
})

const { isLoading } = useImage({ src: props.path })

</script>

<style lang="scss" scoped>
.photo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo__wrapper {
  width: v-bind('size');
  height: v-bind('size');
  max-height: v-bind('size');
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  overflow: hidden;
  border-radius: 40px;
}
</style>
