import { defineStore, storeToRefs } from "pinia";

import { api } from "@/app/api"
import { getMaskedPhone } from '@/helpers/phone';

const fetchUserData = async () => {
  try {
    const { data } = await api.get('/v3/profile');
    return { profile: data.profile }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const updateUser = async (newUser) => {
  try {
    const { data } = await api.put('/v1/profile', newUser)
    return data
  } catch (error) {
    console.error(error)
  }
}

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: {},
    isLoaded: false,
  }),
  actions: {
    async getUser() {
      if (!this.isLoaded) {
        const response = await fetchUserData()
        this.user = response.profile;
        this.isLoaded = true;
      }
    },
    async updateUser(params = {}) {
      const response = await updateUser({
        ...this.user,
        ...params
      })

      this.user = response.profile;

      return response;
    }
  },
  getters: {
    userPhone() {
      return this.user?.phone ? getMaskedPhone(this.user?.phone) : '';
    },
  }
})

export const useUserData = () => {
  const store = useUserStore()
  const { user, isLoaded, userPhone } = storeToRefs(store);

  return { user, isLoaded, userPhone }
}
