<template>
  <div class="variant-toggle">
    <template v-for="option in options">
      <div
        class="variant-item"
        :class="{
          'is-selected': value === option.value
        }"
        @click="value = option.value"
      >
        {{ option.text }}
      </div>
    </template>
  </div>
</template>

<script setup>
const value = defineModel({ type: String, default: '' });
defineProps({
  options: {
    type: Array,
    default: () => [],
  }
})
</script>

<style scoped lang="scss">
.variant-toggle {
  border: 1px solid #E8EAFC;
  border-radius: 50px;
  height: 40px;
  display: flex;
  align-items: center;
}
.variant-item {
  flex: 1;
  text-align: center;
  border-radius: 50px;
  color: #5B5F7C;
  height: 40px;
  @include flex-center;

  &.is-selected {
    background: #5B43EF;
    box-shadow: 0px 5px 20px 0px #4655C4B2;
    color: #fff;
  }
}
</style>
