<template>
  <div
    id="layout-base"
    class="layout-base"
    :class="{ topGap: props.topGap }"
    :style="`gap: ${computedGap}px; padding: ${padding}; background-color: ${backgroundColor}`"
  >
    <div
      v-if="props.title"
      class="title"
    >
      {{ props.title }}
    </div>
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  gap: {
    type: [Boolean, Number, String],
    default: false,
  },
  topGap: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: '20px',
  },
  backgroundColor: {
    type: String,
    default: '#fff',
  },
  title: {
    type: String,
    default: '',
  },
})
const computedGap = computed(() => {
  if (!props.gap) {return 0}
  if (typeof props.gap === 'boolean') {return 20}
  return props.gap
})
</script>

<style lang="scss" scoped>
.layout-base {
  display: grid;
  margin: 0 auto;

  &.topGap {
    padding-top: 30px;
  }
}

.title {
  color: #000000;
  font-size: 17px;
  font-family: "NunitoBold";
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
}
</style>
