<template>
  <button
    class="btn"
    :class="[
      {
        disabled: props.disabled,
        small: props.small,
        'btn--loading': props.loading,
        'btn--text': props.variant === 'text',
        'btn--danger': props.danger,
        [`btn-${props.variant}`]: true,
        'width-auto': widthAuto,
      },
      props.class,
    ]"
  >
    <div class="btn-prepend__icon">
      <slot name="prepend-icon" />
    </div>

    <app-circle-loader
      v-if="props.loading && variant !== 'text'"
      class="btn__loader"
    />
    <span
      v-else
      class="btn__text"
    >
      <slot />
    </span>
  </button>
</template>

<script setup>
import AppCircleLoader from '../AppCircleLoader.vue'

const props = defineProps({
  variant: {
    type: [String, Boolean],
    default: 'default',
    validate(value) {
      return ['text', 'default', 'bordered', 'transparent'].includes(value)
    },
  },
  danger: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: '',
  },
  widthAuto: {
    type: Boolean,
    default: false,
  },
})
</script>
<style lang="scss">
[theme="dark"] {
  .btn--text {
    color: white;

    &:hover {
      color: white;
    }
  }

  .btn-bordered {
    background-color: #424650;
    border: 1px solid #3b3d47;

    .btn__text {
      color: white;
    }
    &:hover, &:focus, &:active {
      background: darken(#424650, 1) !important;
      border: 1px solid #3b3d47;
    }
  }
}

@keyframes shine {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

.btn {
  position: relative;
  width: 100%;
  padding: 19px 10px;
  border-radius: 30px;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  font-weight: 700;
  box-shadow: 0px 5px 20px 0px #4655c4b2;
  background-color: #5b43ef;

  &:not(.btn--text) {
    &:hover {
      background-color: darken($color: #5b43ef, $amount: 8%);
    }
    &:active {
      background-color: rgba(#5b43ef, 70%);
    }
  }

  &__text {
    display: flex;
    align-items: center;
    transition: all 0.3s;
    color: white;
    // color: transparent;
    // background: linear-gradient(
    //     90deg,
    //     #fff 33%,
    //     rgba(255, 255, 255, 0.1) 50%,
    //     #fff 66%
    //   )
    //   transparent;
    // background-size: 300% 100%;
    // background-clip: text;

    font-size: 18px;
    font-weight: 700;
  }

  &--loading {
    pointer-events: none;

    .btn__text {
      animation: shine 2s infinite;
    }
  }

  &-prepend__icon {
    margin-right: 10px;
    color: #fff;
  }

  &--text {
    box-shadow: none;
    background-color: transparent;
    min-height: auto;
    color: #333;

    &:hover {
      color: #777;
    }

    .btn-prepend__icon {
      svg {
        color: #4655c4 !important;
      }
    }

    .btn__text {
      color: #5b43ef;
    }

    &.btn--danger {
      .btn__text {
        color: white;
      }
    }

    &.disabled {
      .btn__text {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }

  &--danger {
    background: #ff6b6b;
    box-shadow: 0px 5px 20px 0px rgba(255, 107, 107, 0.7);

    &:not(.btn--text) {
      &:hover {
        background-color: darken($color: #ff6b6b, $amount: 8%);
      }
      &:active {
        background-color: rgba(#ff6b6b, 70%);
      }
    }
  }

  &.small {
    background: none;
    color: #4734bf;
    box-shadow: none;
    padding: 10px 0;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.btn-transparent {
  background-color: transparent;
  box-shadow: unset;
  border: 1px solid transparent;
  .btn__text {
    color: #5B5F7C;
  }

  &:hover {
    background: lighten(#E8EAFC, 0.4) !important;
    border: 1px solid #c9cbe2;
  }
}

.btn-bordered {
  background-color: #F9FAFD;
  box-shadow: unset;
  border: 1px solid #E8EAFC;

  .btn__text {
    color: #5B5F7C;
  }
  &:hover, &:focus {
    background: darken(#F9FAFD, 1) !important;
    border: 1px solid #c9cbe2;
  }

  &.btn--danger  {
    .btn__text {
      color: #FF3055;
    }
  }
}

.btn-light {
  background-color: #E8EAFC !important;
  border: unset;
  padding: 14px 20px !important;
  box-shadow: none;
  .btn-prepend__icon {
    display: none;
  }
  .btn__text {
    color: #5B5F7C;
    font-size: 14px;
  }
}

.width-auto {
  width: auto;
}
</style>
