
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(createPersistedState({
  storage: window.Telegram?.WebApp?.platform === 'telegram' ? window.Telegram.WebApp.CloudStorage : localStorage,
}));

export { pinia };
