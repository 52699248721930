<template>
  <div>
    <FollowElements />
    <LayoutBase :gap="20">
      <MenuInput
        readonly
        @click="goToSearch"
      />

      <div class="btn-wrapper">
        <RouterLink
          draggable="false"
          to="/chats"
        >
          <ButtonMenu>
            <div class="button-inner">
              <ChatIcon />
              <span class="button-text">
                {{ t('menu.messages') }}
              </span>
              <div
                v-if="counter"
                class="counter"
              >
                {{ counter }}
              </div>
            </div>
          </ButtonMenu>
        </RouterLink>

        <RouterLink
          draggable="false"
          to="/offers"
        >
          <ButtonMenu>
            <div class="button-inner">
              <OrderIcon />
              <span class="button-text">
                {{ t('menu.orders') }}
              </span>
            </div>
          </ButtonMenu>
        </RouterLink>
      </div>

      <CategorySelector class="category" />

      <MenuCardsWrapper>
        <MenuItem
          to="/ambulance"
          :title="t('menu.ambulance')"
          image="ambulance"
          background="#ded8ff"
        />
        <MenuItem
          to="/notifications"
          :title="t('menu.notifications')"
          image="notify"
          background="#ffe3ba"
        />
        <MenuItem
          to="/animals"
          :title="t('menu.animals')"
          image="pet"
          background="#ffe3ba"
        />
        <MenuItem
          to="/companies"
          :title="t('menu.myCompanies')"
          image="bussiness"
          background="#ded8ff"
        />
        <MenuItem
          to="/profile_menu"
          :title="t('menu.profile')"
          image="profile"
          background="#d2d2d2"
        />
        <MenuItem
          to="/favorites"
          :title="t('menu.favorites')"
          image="favorite"
          background="#ffcfd7"
        />
      </MenuCardsWrapper>
    </LayoutBase>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { LayoutBase } from '@/components/Layouts';
import { FollowElements } from '@/components/PaneMain'
import { MenuCardsWrapper, MenuItem } from '@/components/MenuCards';
import { MenuInput } from '@/UI/MenuInput'
import { ChatIcon, OrderIcon } from '@/UI/icons';
import { ButtonMenu } from './components/ButtonMenu';
import { useRouter } from 'vue-router';
import { CategorySelector } from '@/components/CategorySelector';
import { getCounter } from './api'
import { useSocket } from '@/composables/useSocket';

import { useUser } from '@/composables/useUser'

const counter = ref(0)

const router = useRouter()

const goToSearch = () => {
  router.push('/search')
}

const { isAuthenticated } = useUser();
const init = async () => {
  if (isAuthenticated.value) {
    const { count = 0 } = await getCounter()
    counter.value = Number(count)
  }
}

const { socket } = useSocket();
socket.on("message", () => {
  counter.value++
});

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.button-inner {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #FFFFFF;
  gap: 9px;
  position: relative;
}

.order-create {
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 12px;
  position: absolute;
  background: #4655C4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.category {
  margin: 0 -20px;
  padding: 0 20px;
}

.btn-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.counter {
  position: absolute;
  right: -5px;
  top: -2px;
  background: #fff;
  color: #4655C4;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
