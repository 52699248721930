<template>
  <BlockHeader
    :title="isShowAnimalForm ? t('companyCreate.animalSelectStep.title') : t('companyCreate.animalStep.title')"
    :text="isShowAnimalForm ? t('companyCreate.animalSelectStep.description') : t('companyCreate.animalStep.description')"
  />

  <AnimalManySelector v-if="isShowAnimalTypeSelector" v-model="companyInfo.animalTypes" :error="errors?.animalTypes && errors?.animalTypes[0]?.message" />

  <template v-if="isShowAnimalForm">
    TODO <br><br>
    Реализовать компонент выбора животного или создания нового
  </template>
</template>

<script setup>
import { computed } from 'vue';
import { AnimalManySelector } from '@/components/AnimalManySelector'

import BlockHeader from '../components/BlockHeader.vue';

defineProps({
  errors: {
    type: Object,
    default: () => {},
  }
})

const companyInfo = defineModel({ type: Object, default: () => {} })

const isShowAnimalTypeSelector = computed(() => (
  ![5, 24].includes(companyInfo.value.categoryId) &&
    !(companyInfo.value.categoryId === 13) &&
    !(companyInfo.value.categoryId === 8 && companyInfo.value.executor_type === 1)
))

const isShowAnimalForm = computed(() => {
  if (companyInfo.value.categoryId === 8 && companyInfo.value.executor_type === 2) {
    return false
  }
  return [8, 13].includes(companyInfo.value.categoryId)
})
</script>

<style scoped lang="scss">
</style>
