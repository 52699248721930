export const daysOptions = [
  {
    id: 'Mon',
    name: 'Понедельник',
    sort: 1
  },
  {
    id: 'Tu',
    name: 'Вторник',
    sort: 2
  },
  {
    id: 'Wed',
    name: 'Среда',
    sort: 3
  },
  {
    id: 'Th',
    name: 'Четверг',
    sort: 4
  },
  {
    id: 'Fri',
    name: 'Пятница',
    sort: 5
  },
  {
    id: 'Sat',
    name: 'Суббота',
    sort: 6
  },
  {
    id: 'Sun',
    name: 'Воскресенье',
    sort: 7
  },
]
