import { api } from "@/app/api"

export const createCompany = async (companyInfo) => {
  try {
    const { data } = await api.post('/v1/accounts', {
      account_type_id: companyInfo.account_type_id,
      payload: companyInfo
    } )
    return data
  } catch (error) {
    console.error(error)
  }
}
